import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FilledButton from "../../Components/UI/Buttons/FilledButton";
import axios from "axios";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

const ProjectCategories = [
  "Embedded",
  "IoT",
  "PCB",
  "Front End",
  "Mobile",
  "Back End, Database"
];
const IndustryCategories = [
  "Health care",
  "Finance",
  "Defence",
  "Public Sector",
  "Manufacturing",
  "Telecom",
  "Ecommerce"
];
function AdminPanel() {
  const [title, setTitle] = useState("");
  const [industry, setIndustry] = useState("");
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [showIndustryForm, setShowIndustryForm] = useState(false);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showReviewsForm, setShowReviewsForm] = useState(false);
  const [savedData, setSavedData] = useState([]);
  const [features, setFeatures] = useState("");

  const [showTeamMemberForm, setShowTeamMemberForm] = useState(false);

  const [teamMembers, setTeamMembers] = useState([
    { id: 1, name: "", email: "", role: "", image: "" }
  ]);
  // const [clientName, setClientName] = useState("");
  // const [countryName, setCountryName] = useState("");
  // const [clientMessage, setClientMessage] = useState("");

  const [reviews, setReviews] = useState([
    { clientName: "", countryName: "", clientMessage: "" }
  ]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedReviews = [...reviews];
    updatedReviews[index][name] = value;
    setReviews(updatedReviews);
  };

  const addReview = () => {
    setReviews([
      ...reviews,
      { projectName: "", clientName: "", countryName: "", clientMessage: "" }
    ]);
  };
  const handleReviewSubmit = async () => {
    try {
      // Submit each review
      for (const review of reviews) {
        // await axios.post(process.env.REACT_APP_BACKEND_URL +'/clientReviews',
        await axios.post(process.env.REACT_APP_BACKEND_URL +"/clientReviews", {
          projectName: review.projectName,
          name: review.clientName,
          country: review.countryName,
          message: review.clientMessage
        });
      }
      // Clear the form after submission
      setReviews([
        { projectName: "", clientName: "", countryName: "", clientMessage: "" }
      ]);
      alert("Reviews submitted successfully");
    } catch (error) {
      console.error("Error submitting reviews:", error);
      alert("Error submitting reviews, fill all fields with String DataType");
    }
  };

  ///////////////////////////////////////////////////////////////////////////////

  const handleFeatureChange = (event) => {
    setFeatures(event.target.value);
  };

  //GET API CALL for projects.....
  const [projectsData, setProjectsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    axios
      // .get(process.env.REACT_APP_BACKEND_URL +"/project")
      .get(process.env.REACT_APP_BACKEND_URL +"/project")
      .then((response) => {
        setProjectsData(response.data);
        setCategories(response.data.map((item) => item.category));
        setSelectedCategory(response.data[0]?.category);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);
  const projectDescriptionStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2, // Maximum number of lines to display
    WebkitBoxOrient: "vertical"
  };

  const columns = [
    {
      accessorKey: "category",
      header: "Category",
      muiTableHeadCellProps: { sx: { color: "" } }
    },
    {
      accessorKey: "projects",
      header: "Projects",
      Cell: ({ cell }) => (
        <ul>
          {cell.getValue().map((project) => (
            <li key={project._id}>
              <strong>{project.title}</strong>
              <p style={projectDescriptionStyle}>{project.description}</p>
            </li>
          ))}
        </ul>
      )
    }
  ];

  const tableData = categories.map((category) => ({
    category,
    projects:
      projectsData.find((item) => item.category === category)?.projects || []
  }));
  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableColumnOrdering: true,
    enablePagination: false
  });

  //POST API CALL.....

  const handleSubmitProjects = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i]);
    }
    formData.append("description", description);
    formData.append("category", category);
    formData.append("industry", industry);
    formData.append(
      "bussinues",
      JSON.stringify(savedData.map((item) => item.value))
    );
    formData.append(
      "challenges",
      JSON.stringify(savedData1.map((item) => item.value))
    );
    formData.append(
      "solutions",
      JSON.stringify(savedData2.map((item) => item.value))
    );
    formData.append(
      "objectives",
      JSON.stringify(savedData3.map((item) => item.value))
    );
    formData.append(
      "technologies",
      JSON.stringify(savedData4.map((item) => item.value))
    );

    // Split the features string into an array of features
    const featuresArray = features.split(",");
    featuresArray.forEach((feature, index) => {
      formData.append(`features[${index}]`, feature);
    });

    try {
      // await axios.post(process.env.REACT_APP_BACKEND_URL +"/project", formData);
      await axios.post(process.env.REACT_APP_BACKEND_URL +"/project", formData);
      setTitle("");
      resetImages();
      setDescription("");
      setFeatures("");
      setIndustry("");
      setCategory("");
      alert("Project added successfully");
    } catch (error) {
      console.error(error);
      alert("Error occurred while adding project");
    }
  };

  const resetImages = () => {
    document.getElementById("fileInput").value = "";
    setImages([]);
  };

  //form handeling
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  // *******1st
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabData = ["Bus", "Chal", "Pr", "Obj", "Tech"];
  //
  const [fields, setFields] = useState([{ id: 1, value: "" }]);

  const addField = () => {
    const newFields = [...fields, { id: fields.length + 1, value: "" }];
    setFields(newFields);
  };

  const handleFieldChange = (id, value) => {
    const updatedFields = fields.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields(updatedFields);
  };
  const handleDoneClick = () => {
    // Filter out fields with no value
    const nonEmptyFields = fields.filter((field) => field.value.trim() !== "");
    // Save data to the array
    setSavedData(nonEmptyFields);
    // Log the saved data to the console
    // //console.log(
    //   "Saved Data:",
    //   JSON.stringify(savedData.map((item) => item.value))
    // );
  };
  // *************1st

  // *******2nd

  const [fields1, setFields1] = useState([{ id: 1, value: "" }]);
  const [savedData1, setSavedData1] = useState([]);
  const addField1 = () => {
    const newFields1 = [...fields1, { id: fields1.length + 1, value: "" }];
    setFields1(newFields1);
  };

  const handleFieldChange1 = (id, value) => {
    const updatedFields1 = fields1.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields1(updatedFields1);
  };
  const handleDoneClick1 = () => {
    // Filter out fields with no value
    const nonEmptyFields1 = fields1.filter(
      (field) => field.value.trim() !== ""
    );
    // Save data to the array
    setSavedData1(nonEmptyFields1);
    // Log the saved data to the console
    //console.log("Saved Data:", nonEmptyFields1);
  };
  // *************2nd

  // *******3rd

  const [fields2, setFields2] = useState([{ id: 1, value: "" }]);
  const [savedData2, setSavedData2] = useState([]);
  const addField2 = () => {
    const newFields2 = [...fields2, { id: fields2.length + 1, value: "" }];
    setFields2(newFields2);
  };

  const handleFieldChange2 = (id, value) => {
    const updatedFields2 = fields2.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields2(updatedFields2);
  };
  const handleDoneClick2 = () => {
    // Filter out fields with no value
    const nonEmptyFields2 = fields2.filter(
      (field) => field.value.trim() !== ""
    );
    // Save data to the array
    setSavedData2(nonEmptyFields2);
    // Log the saved data to the console
    //console.log("Saved Data:", nonEmptyFields2);
  };
  // *************3rd

  // *******4rth
  const [fields3, setFields3] = useState([{ id: 1, value: "" }]);
  const [savedData3, setSavedData3] = useState([]);
  const addField3 = () => {
    const newFields3 = [...fields3, { id: fields3.length + 1, value: "" }];
    setFields3(newFields3);
  };

  const handleFieldChange3 = (id, value) => {
    const updatedFields3 = fields3.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields3(updatedFields3);
  };
  const handleDoneClick3 = () => {
    // Filter out fields with no value
    const nonEmptyFields3 = fields3.filter(
      (field) => field.value.trim() !== ""
    );
    // Save data to the array
    setSavedData3(nonEmptyFields3);
    // Log the saved data to the console
    //console.log("Saved Data:", nonEmptyFields3);
  };
  // *************4rth

  // *******5th
  const [fields4, setFields4] = useState([{ id: 1, value: "" }]);
  const [savedData4, setSavedData4] = useState([]);
  const addField4 = () => {
    const newFields4 = [...fields4, { id: fields4.length + 1, value: "" }];
    setFields4(newFields4);
  };

  const handleFieldChange4 = (id, value) => {
    const updatedFields4 = fields4.map((field) =>
      field.id === id ? { ...field, value } : field
    );
    setFields4(updatedFields4);
  };
  const handleDoneClick4 = () => {
    // Filter out fields with no value
    const nonEmptyFields4 = fields4.filter(
      (field) => field.value.trim() !== ""
    );
    // Save data to the array
    setSavedData4(nonEmptyFields4);
    // Log the saved data to the console
    //console.log("Saved Data:", nonEmptyFields4);
  };
  // *************5th

  const renderTabContent = (index) => {
    switch (index) {
      case 0:
        return (
          <div>
            {fields.map((field) => (
              <TextField
                key={field.id}
                label={`Field ${field.id}`}
                value={field.value}
                onChange={(e) => handleFieldChange(field.id, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
            <IconButton
              onClick={addField}
              color="primary"
              aria-label="Add Field"
            >
              <AddIcon />
            </IconButton>
            <br></br>
            <Button
              onClick={handleDoneClick}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>
        );
      case 1:
        return (
          <div>
            {fields1.map((field) => (
              <TextField
                key={field.id}
                label={`Field ${field.id}`}
                value={field.value}
                onChange={(e) => handleFieldChange1(field.id, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
            <IconButton
              onClick={addField1}
              color="primary"
              aria-label="Add Field"
            >
              <AddIcon />
            </IconButton>
            <br></br>
            <Button
              onClick={handleDoneClick1}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>
        );
      case 2:
        return (
          <div>
            {fields2.map((field) => (
              <TextField
                key={field.id}
                label={`Field ${field.id}`}
                value={field.value}
                onChange={(e) => handleFieldChange2(field.id, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
            <IconButton
              onClick={addField2}
              color="primary"
              aria-label="Add Field"
            >
              <AddIcon />
            </IconButton>
            <br></br>
            <Button
              onClick={handleDoneClick2}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>
        );
      case 3:
        return (
          <div>
            {fields3.map((field) => (
              <TextField
                key={field.id}
                label={`Field ${field.id}`}
                value={field.value}
                onChange={(e) => handleFieldChange3(field.id, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
            <IconButton
              onClick={addField3}
              color="primary"
              aria-label="Add Field"
            >
              <AddIcon />
            </IconButton>
            <br></br>
            <Button
              onClick={handleDoneClick3}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>
        );

      case 4:
        return (
          <div>
            {fields4.map((field) => (
              <TextField
                key={field.id}
                label={`Field ${field.id}`}
                value={field.value}
                onChange={(e) => handleFieldChange4(field.id, e.target.value)}
                fullWidth
                margin="normal"
              />
            ))}
            <IconButton
              onClick={addField4}
              color="primary"
              aria-label="Add Field"
            >
              <AddIcon />
            </IconButton>
            <br></br>
            <Button
              onClick={handleDoneClick4}
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////

 

  // Form data for adding team member
  const teamMemberFormData = new FormData();
  teamMemberFormData.append("name", teamMembers.name);
  teamMemberFormData.append("email", teamMembers.email);
  teamMemberFormData.append("role", teamMembers.role);
  teamMemberFormData.append("photo", teamMembers.photo); // Assuming photo is of type File
  teamMemberFormData.append("description", teamMembers.description);

  const handleSubmitTeamMember = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL +"/team-member",
        teamMemberFormData
      );
      setOpen(false); // Close the dialog after submission
      alert("Team member added successfully!");
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL +"/team-members");
      setTeamMembersData(response.data); // Update the state
    } catch (error) {
      console.error("Error adding team member:", error);
      alert("Error occurred while adding team member.");
    }
  };

  const handleTeamMemberClick = () => {
    setShowTeamMemberForm(true); // Show the form
    setOpen(true); // Open the dialog
  };

  const [teamMembersData, setTeamMembersData] = useState([]);
  const [editTeamMember, setEditTeamMember] = useState(null); // State for editing form
  const [showEditModal, setShowEditModal] = useState(false); // Show edit modal

  useEffect(() => {
    const fetchTeamMembersData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL +"/team-members"
        );
        setTeamMembersData(response.data); // Set data when fetched successfully
      } catch (error) {
        console.error("Error fetching team members data:", error);
      }
    };

    fetchTeamMembersData(); // Call fetch method
  }, []); // Run on component mount

  const handleUpdateTeamMember = async (teamMember) => {
    //console.log("here is the updated editable team member", teamMember);

   const formData = new FormData();
  formData.append("name", teamMember.name);
  formData.append("email", teamMember.email);
  formData.append("role", teamMember.role);
  formData.append("description", teamMember.description);

  // Conditionally append photo only if it's a valid file
  if (teamMember.photo && teamMember.photo instanceof File) {
    formData.append("photo", teamMember.photo);
  }else{
    formData.append("photo", teamMember.photo);
  }

    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL +`/team-member/${teamMember.id}`,
        formData
      );
      setShowEditModal(false); // Close the edit dialog
      setTeamMembersData((prevData) =>
        prevData.map((member) =>
          member.id === teamMember.id ? teamMember : member
        )
      );
    
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL +"/team-members");
    setTeamMembersData(response.data); // Update the state
      alert("Team member updated successfully!");
    
    } catch (error) {
      console.error("Error updating team member:", error);
      alert("Error occurred while updating team member.");
    }
};


  const handleDeleteTeamMember = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_BACKEND_URL +`/team-member/${id}`);
      setTeamMembersData((prevData) =>
        prevData.filter((member) => member.id !== id)
      );
      alert("Team member deleted successfully!");
     // Fetch updated team members data here
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL +"/team-members");
    setTeamMembersData(response.data); // Update the state
    } catch (error) {
      console.error("Error deleting team member:", error);
      alert("Error occurred while deleting team member.");
    }
  };

  const handleEditClick = (teamMember) => {
    setEditTeamMember(teamMember); // Set the member to edit
    setShowEditModal(true); // Open the edit dialog
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    setEditTeamMember(null);
  };

  const tableColumns = [
    {
      accessorKey: "name",
      header: "Name"
    },
    {
      accessorKey: "email",
      header: "Email"
    },
    {
      accessorKey: "role",
      header: "Role"
    },
    {
      accessorKey: "photo",
      header: "Photo",
      Cell: ({ cell }) => (
        <img
          src={cell.getValue()}
          alt="Profile"
          style={{ width: 50, height: 50, borderRadius: "50%" }}
        />
      )
    },
    {
      accessorKey: "description",
      header: "Description"
    },
    {
      id: "actions", // Custom column for actions
      header: "Actions",
      Cell: ({ row }) => (
        <div>
          <Button onClick={() => handleEditClick(row.original)}>Edit</Button>
          <Button
            onClick={() => handleDeleteTeamMember(row.original.id)}
            color="error"
          >
            Delete
          </Button>
        </div>
      )
    }
  ];

  const tableData2 = teamMembersData.map((teamMember) => ({
    id: teamMember._id,
    name: teamMember.name,
    email: teamMember.email,
    role: teamMember.role,
    photo: teamMember.photo,
    description: teamMember.description
  }));

  const table2 = useMaterialReactTable({
    columns: tableColumns, // The columns you defined for your table
    data: tableData2, // The mapped data with the additional projects field
    enableColumnOrdering: true,
    enablePagination: false
  });

  //////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////

  // Form data for adding team member
  const [openJobForm, setOpenJobForm] = useState(false);
  const [showEditJobModal, setShowEditJobModal] = useState(false);

  const [editJob, setEditJob] = useState(null); // State for editing form

  //console.log("edit model of images checkk ||||||||||" , editJob)

  const [jobForm, setJobForm] = useState({
    jobTitle: "",
    description: "",
    image: null,
    dueDate: "",
    summary: "",
    experience: "",
    qualifications: "",
  });
  const [jobs, setJobs] = useState([]);

  //console.log("><><><><>< here are the jobs ", jobs);

  const jobFormData = new FormData();
  jobFormData.append("jobTitle", jobForm.jobTitle);
  jobFormData.append("description", jobForm.description);
  jobFormData.append("dueDate", jobForm.dueDate);
  jobFormData.append("summary", jobForm.summary);
  jobFormData.append("experience", jobForm.experience);
  jobFormData.append("qualifications", jobForm.qualifications);
  
  // Append the image without type checks
  jobFormData.append("photo", jobForm.image instanceof File ? jobForm.image : undefined);
  
  //console.log('jobForm.image: ', jobForm.image);
  //console.log('jobForm:', jobForm); // Log image and all form data


  //console.log('jobForm.image: <><><><><><><><>', jobForm.image);  // Log image object or file

  



  const jobColumns = [
    { header: "Job Title", accessorKey: "jobTitle" },
    { header: "Description", accessorKey: "description" },
    { header: "Due Date", accessorKey: "dueDate" },
    { header: "Summary", accessorKey: "summary" },
    { header: "Experience", accessorKey: "experience" },
    { header: "Qualifications", accessorKey: "qualifications" },
    {
      header: "Image",
      accessorKey: "image",
      Cell: ({ cell }) => (
        <img
          src={cell.getValue()}
          alt="Job"
          style={{ width: 50, height: 50, borderRadius: "50%" }}
        />
      )
    },
    {
      header: "Actions",
      accessorKey: "actions",
      Cell: ({ row }) => (
        <Box>
          <Button onClick={() => handleEditClickJob(row.original)}>Edit</Button>
          <Button onClick={() => handleDeleteJob(row.original.id)}>Delete</Button>
        </Box>
      )
    }
  ];
  


  const handleEditClickJob = (job) => {
    setEditJob(job); // Set the member to edit
    setShowEditJobModal(true); // Open the edit dialog
  };

  const handleEditCloseJob = () => {
    setShowEditJobModal(false);
    setEditJob(null);
  };



  const tableData4 = jobs.length
    ? jobs.map((job) => ({
        id: job._id, // Use job._id as the unique identifier
        jobTitle: job.jobTitle,
        description: job.description,
        dueDate: new Date(job.dueDate).toLocaleDateString(), // Format the due date
        summary: job.summary,
        experience: job.experience,
        qualifications: job.qualifications,

        image: job.image// Render the image
      }))
    : []; // Return an empty array if jobs is empty or null

  //console.log("Table Data:", tableData4);

  const table4 = useMaterialReactTable({
    columns: jobColumns,
    data: tableData4, // Ensure tableData4 is properly mapped and contains valid job data
    enableColumnOrdering: true,
    enablePagination: false
  });

  const handleJobClick = () => {
    setOpen(true); // Show the form
  };

  const handleCloseJobs = () => {
    setOpen(false); // Close the form
    setJobForm({
      jobTitle: "",
      description: "",
      image: null,
      dueDate: "",
      summary: "",
      experience: "",
      qualifications: ""
    });
  };

  const handleAddJob = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +"/job",
        jobFormData
      );
      setJobs((prevJobs) => [...prevJobs, response.data]); // Add new job to the list
      alert("Job added successfully!");
      handleCloseJobs(); // Close the form after submission
    } catch (error) {
      console.error("Error adding job:", error);
      alert("Error occurred while adding job.");
    }
  };

  const deepClone = (obj) => {
    const seen = new WeakMap();
  
    // return JSON.parse(JSON.stringify(obj, (key, value) => {
    //   if (key === 'image' && value instanceof File) {
    //     // Return the file object directly without cloning
    //     return value;
    //   }
    //   if (value && typeof value === 'object') {
    //     if (seen.has(value)) {
    //       return undefined; // Break circular references
    //     }
    //     seen.set(value, true); // Track the reference
    //   }
    //   return value;
    // }));

    return obj
  };
  
  
  
  // const handleEditJob = async (editJob) => {
  //   //console.log("The updated job data: ", editJob);
    
  //   if (!window.confirm("Are you sure you want to update this job?")) {
  //     return; // Exit if user cancels the confirmation
  //   }
    
  //   try {
  //     // Deep clone the editJob object to avoid circular references
  //     const cloneEditJob = deepClone(editJob);
      
  //     const response = await axios.put(
  //       process.env.REACT_APP_BACKEND_URL +`/job/${cloneEditJob.id}`,
  //       cloneEditJob
  //     );
      
  //     setJobs((prevJobs) =>
  //       prevJobs.map((job) => (job.id === cloneEditJob.id ? response.data : job))
  //     );
  //     setShowEditJobModal(false); // Close the modal
  //     alert("Job updated successfully!");
  //   } catch (error) {
  //     if (error.response) {
  //       console.error("Error response:", error.response.data);
  //       alert(`Error: ${error.response.data.message || "An error occurred"}`);
  //     } else if (error.request) {
  //       console.error("No response from server:", error.request);
  //       alert("No response from server. Please check your connection.");
  //     } else {
  //       console.error("Error setting up the request:", error.message);
  //       alert("An error occurred while updating the job.");
  //     }
  //   }
  // };
  

  const handleEditJob = async (editJob) => {
    //console.log("The updated job data: ", editJob);
  
    if (!window.confirm("Are you sure you want to update this job?")) {
      return; // Exit if user cancels the confirmation
    }
  
    try {
      // Deep clone the editJob object to avoid circular references
      const cloneEditJob = deepClone(editJob);
  
      //console.log("cloneEditJob: ", cloneEditJob);
  
      // Create FormData to send image along with other fields if updated
      const editJobFormData = new FormData();
      editJobFormData.append("jobTitle", cloneEditJob.jobTitle);
      editJobFormData.append("description", cloneEditJob.description);
  
      // Conditionally add image only if it's a valid File object
      if (cloneEditJob.image && cloneEditJob.image instanceof File) {
        editJobFormData.append("image", cloneEditJob.image);
      } else {
        editJobFormData.append("photo", cloneEditJob.image);
      }
  
      editJobFormData.append("dueDate", cloneEditJob.dueDate);
      editJobFormData.append("summary", cloneEditJob.summary);
      editJobFormData.append("experience", cloneEditJob.experience);
      editJobFormData.append("qualifications", cloneEditJob.qualifications);
  
      const response = await axios.put(
        process.env.REACT_APP_BACKEND_URL +`/job/${cloneEditJob.id}`,
        editJobFormData
      );
  
      setJobs((prevJobs) =>
        prevJobs.map((job) => (job.id === cloneEditJob.id ? response.data : job))
      );
      setShowEditJobModal(false); // Close the modal
      const response2 = await axios.get(process.env.REACT_APP_BACKEND_URL +"/jobs");
      setJobs(response2.data); // Set jobs as an array
      alert("Job updated successfully!");
  
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
        alert(`Error: ${error.response.data.message || "An error occurred"}`);
      } else if (error.request) {
        console.error("No response from server:", error.request);
        alert("No response from server. Please check your connection.");
      } else {
        console.error("Error setting up the request:", error.message);
        alert("An error occurred while updating the job.");
      }
    }
  };
  
  

  const handleDeleteJob = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_BACKEND_URL +`/job/${id}`);
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== id)); // Remove job from list
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL +"/jobs");
        setJobs(response.data); // Set jobs as an array
      alert("Job deleted successfully!");
    } catch (error) {
      console.error("Error deleting job:", error);
      alert("Error occurred while deleting job.");
    }
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL +"/jobs");
        setJobs(response.data); // Set jobs as an array
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs(); // Call fetchJobs to populate jobs
  }, []); // Empty dependency array if fetchJobs is not called multiple times

  const handleSubmitJob = async () => {
    try {
      const jobFormData = new FormData();
      jobFormData.append("jobTitle", jobForm.jobTitle);
      jobFormData.append("description", jobForm.description);
      jobFormData.append("image", jobForm.image); // Assuming it's a file
      jobFormData.append("dueDate", jobForm.dueDate);
      jobFormData.append("summary", jobForm.summary);
      jobFormData.append("experience", jobForm.experience);
      jobFormData.append("qualifications", jobForm.qualifications);

      //console.log("here  ,.,.,., is the job form data ", jobForm);

      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL +"/job",
        jobFormData
      );
      setJobs((prevJobs) => [...prevJobs, response.data]); // Add new job to the list
      alert("Job added successfully!");
      handleCloseJobs(); // Close the form after submission
    } catch (error) {
      console.error("Error adding job:", error);
      alert("Error occurred while adding job.");
    }
  };

  // const handleSubmitJob = async () => {
  //   try {
  //     // Prepare FormData
  //     const jobFormData = new FormData();
  //     jobFormData.append("jobTitle", jobForm.jobTitle);
  //     jobFormData.append("description", jobForm.description);
  //     jobFormData.append("image", jobForm.image); // Assuming it's a file
  //     jobFormData.append("dueDate", jobForm.dueDate);
  //     jobFormData.append("summary", jobForm.summary);

  //     // Post job data to the API
  //     const response = await axios.post(process.env.REACT_APP_BACKEND_URL +"/job", jobFormData);
  //     setJobs((prevJobs) => [...prevJobs, response.data]); // Add new job to the list
  //     alert("Job added successfully!");
  //     handleCloseJobs(); // Close the form after submission
  //   } catch (error) {
  //     console.error("Error adding job:", error);
  //     alert("Error occurred while adding job.");
  //   }
  // };

  return (
    <Container
      maxWidth="md"
      sx={{ marginTop: "10%" }}
      style={{ background: "" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem"
        }}
      >
        <button onClick={handleLogout}>Logout</button>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Button
            onClick={() => {
              setShowIndustryForm(true);
              setShowProjectForm(false);
              setOpenJobForm(false);
              setShowTeamMemberForm(false);
              setShowReviewsForm(false);
            }}
          >
            Manage Industries
          </Button>
          <Button
            onClick={() => {
              setShowProjectForm(true);
              setShowIndustryForm(false);
              setShowTeamMemberForm(false);
              setShowReviewsForm(false);
              setOpenJobForm(false);
            }}
          >
            Manage Projects
          </Button>

          <Button
            onClick={() => {
              setShowTeamMemberForm(true);
              setOpenJobForm(false);
              setShowProjectForm(false);
              setShowIndustryForm(false);
              setShowReviewsForm(false);
            }}
          >
            Manage Team
          </Button>

          <Button
            onClick={() => {
              setOpenJobForm(true);
              // setEditJob(null);
              setShowTeamMemberForm(false);

              setShowProjectForm(false);
              setShowIndustryForm(false);
              setShowReviewsForm(false);
            }}
          >
            Manage Jobs
          </Button>

          <Button
            onClick={() => {
              setShowProjectForm(false);
              setShowIndustryForm(false);
              setShowTeamMemberForm(false);
              setOpenJobForm(false);
              setShowReviewsForm(true);
            }}
          >
            Manage Reviews
          </Button>
        </Box>

        {openJobForm && (
          <>
            <Button onClick={handleJobClick}>Add Job</Button>
            <MaterialReactTable table={table4} />

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Job</DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "5%", paddingBottom: "5%" }}
                  >
                    Add Job
                  </Typography>
                  <TextField
                    label="Job Title"
                    value={jobForm.jobTitle}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, jobTitle: e.target.value })
                    }
                    required
                    sx={{ paddingBottom: "5%" }}
                  />

                  <TextField
                    label="Description"
                    value={jobForm.description}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, description: e.target.value })
                    }
                    required
                    multiline
                    rows={4}
                    sx={{ paddingBottom: "5%" }}
                  />

                  <input
                    type="file"
                    onChange={(e) =>
                      setJobForm({ ...jobForm, image: e.target.files[0] })
                    }
                    required
                    style={{ paddingBottom: "5%" }}
                  />

                  <TextField
                    label="Due Date"
                    type="date"
                    value={jobForm.dueDate}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, dueDate: e.target.value })
                    }
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{ paddingBottom: "5%" }}
                  />

                  <TextField
                    label="Summary"
                    value={jobForm.summary}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, summary: e.target.value })
                    }
                    required
                    sx={{ paddingBottom: "5%" }}
                  />
                  <TextField
                    label="Experience"
                    value={jobForm.experience}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, experience: e.target.value })
                    }
                    required
                    sx={{ paddingBottom: "5%" }}
                  />
                  <TextField
                    label="Qualifications"
                    value={jobForm.qualifications}
                    onChange={(e) =>
                      setJobForm({ ...jobForm, qualifications: e.target.value })
                    }
                    required
                    sx={{ paddingBottom: "5%" }}
                  />

                  <FilledButton text={"Submit"} onClick={handleSubmitJob} />
                  <Button onClick={handleClose}>Close</Button>
                </Box>
              </DialogContent>
            </Dialog>


            
          </>
        )}

        {showTeamMemberForm && (
          <>
            <Button onClick={handleTeamMemberClick}>Add Team Member</Button>
            <MaterialReactTable table={table2} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Add Team Member</DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "5%", paddingBottom: "5%" }}
                  >
                    Add Team Member
                  </Typography>
                  <TextField
                    label="Name"
                    value={teamMembers.name}
                    onChange={(e) =>
                      setTeamMembers({ ...teamMembers, name: e.target.value })
                    }
                    required
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <TextField
                    label="Role"
                    value={teamMembers.role}
                    onChange={(e) =>
                      setTeamMembers({ ...teamMembers, role: e.target.value })
                    }
                    required
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <TextField
                    label="Email"
                    type="email"
                    value={teamMembers.email}
                    onChange={(e) =>
                      setTeamMembers({ ...teamMembers, email: e.target.value })
                    }
                    required
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <input
                    type="file"
                    onChange={(e) =>
                      setTeamMembers({
                        ...teamMembers,
                        photo: e.target.files[0]
                      })
                    }
                    required
                    style={{ paddingBottom: "5%" }}
                  />
                  <TextField
                    label="Description"
                    value={teamMembers.description}
                    onChange={(e) =>
                      setTeamMembers({
                        ...teamMembers,
                        description: e.target.value
                      })
                    }
                    required
                    multiline
                    rows={4}
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <FilledButton
                    text={"Submit"}
                    onClick={handleSubmitTeamMember}
                  />
                  <Button onClick={handleClose}>Close</Button>
                </Box>
              </DialogContent>
            </Dialog>
          </>
        )}

        {showReviewsForm && (
          <>
            <Typography
              variant="h4"
              sx={{ paddingTop: "5%", paddingBottom: "5%" }}
            >
              Welcome to Octathorn Admin Panel
            </Typography>
          </>
        )}

        {showProjectForm && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Add Projects
            </Button>
            <MaterialReactTable table={table} />

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              sx={{ maxWidth: "100%" }}
              style={{ maxWidth: "100%" }}
            >
              <DialogTitle id="form-dialog-title">
                Manage Featured Projects
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "5%", paddingBottom: "5%" }}
                  >
                    Manage Featured Projects
                  </Typography>
                  <TextField
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <input
                    type="file"
                    multiple
                    onChange={(e) => setImages(e.target.files)}
                    required
                    style={{ paddingBottom: "5%" }}
                    id="fileInput"
                  />

                  <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                  />
                  <Autocomplete
                    sx={{ paddingTop: "0%", paddingBottom: "5%", width: 230 }}
                    value={category}
                    onChange={(event, newValue) => setCategory(newValue)}
                    options={ProjectCategories}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Category" required />
                    )}
                  />
                  <Autocomplete
                    sx={{ paddingTop: "0%", paddingBottom: "5%", width: 230 }}
                    value={industry}
                    onChange={(event, newValue) => setIndustry(newValue)}
                    options={IndustryCategories}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Industry" required />
                    )}
                  />
                  <TextField
                    label="Features"
                    value={features}
                    onChange={handleFeatureChange}
                    required
                    multiline
                    rows={4}
                    sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                    helperText="Enter features separated by commas"
                  />
                  {/* Extra Header */}
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        overflowX: "auto"
                      }}
                    >
                      <Tabs value={value} onChange={handleChange} centered>
                        {tabData.map((tab, index) => (
                          <Tab label={tab} key={index} />
                        ))}
                      </Tabs>
                    </Box>
                    <Typography sx={{ p: 2 }}>
                      {renderTabContent(value)}
                    </Typography>
                  </Box>
                  {/* Extra Header */}

                  <FilledButton
                    text={"Submit"}
                    onClick={handleSubmitProjects}
                  />
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
            <Button
              onClick={() => {
                setShowProjectForm(false);
              }}
            >
              Close Projects
            </Button>
          </>
        )}

        {!showIndustryForm &&
          !showProjectForm &&
          !showTeamMemberForm &&
          !openJobForm && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Add Project Reviews
              </Button>
              <MaterialReactTable table={table} />

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                sx={{ maxWidth: "100%" }}
                style={{ maxWidth: "100%" }}
              >
                <DialogTitle id="form-dialog-title">
                  Manage Featured Projects
                </DialogTitle>
                <DialogContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ paddingTop: "5%", paddingBottom: "5%" }}
                    >
                      Add Projects Reviews
                    </Typography>

                    <div>
                      {reviews.map((review, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%"
                          }}
                        >
                          <Typography
                            sx={{ textAlign: "start", fontSize: "1.3rem" }}
                          >
                            Add client Review {index + 1}
                          </Typography>
                          <TextField
                            name="projectName"
                            label="Project Name"
                            value={review.projectName}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                            sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                          />
                          <TextField
                            name="clientName"
                            label="Client Name"
                            value={review.clientName}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                            sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                          />
                          <TextField
                            name="countryName"
                            label="Country Name"
                            value={review.countryName}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                            sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                          />
                          <TextField
                            name="clientMessage"
                            label="Review Message"
                            value={review.clientMessage}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                            sx={{ paddingTop: "0%", paddingBottom: "5%" }}
                          />
                        </Box>
                      ))}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          margin: "1rem"
                        }}
                      >
                        <Button
                          onClick={addReview}
                          variant="contained"
                          color="primary"
                        >
                          Add Review
                        </Button>
                        <Button
                          onClick={handleReviewSubmit}
                          variant="contained"
                          color="primary"
                        >
                          Submit Review
                        </Button>
                      </Box>
                    </div>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
            </>
          )}
      </Box>

      {/* Edit Team Member Modal */}
      {/* <Modal open={showEditModal} onClose={handleEditClose}>
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "20% auto",
            width: "50%"
          }}
        >
          <h2>Edit Team Member</h2>
          {editTeamMember && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateTeamMember(editTeamMember);
              }}
            >
              <TextField
                label="Name"
                value={editTeamMember.name}
                onChange={(e) =>
                  setEditTeamMember({ ...editTeamMember, name: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Email"
                value={editTeamMember.email}
                onChange={(e) =>
                  setEditTeamMember({
                    ...editTeamMember,
                    email: e.target.value
                  })
                }
                fullWidth
              />
              <TextField
                label="Role"
                value={editTeamMember.role}
                onChange={(e) =>
                  setEditTeamMember({ ...editTeamMember, role: e.target.value })
                }
                fullWidth
              />
              <TextField
                label="Description"
                value={editTeamMember.description}
                onChange={(e) =>
                  setEditTeamMember({
                    ...editTeamMember,
                    description: e.target.value
                  })
                }
                fullWidth
              />
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </form>
          )}
        </div>
      </Modal> */}

      {/* Edit Team Member Modal */}
<Modal open={showEditModal} onClose={handleEditClose}>
  <div
    style={{
      padding: "20px",
      backgroundColor: "white",
      margin: "20% auto",
      width: "50%"
    }}
  >
    <h2>Edit Team Member</h2>
    {editTeamMember && (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUpdateTeamMember(editTeamMember);
        }}
      >
        <TextField
          label="Name"
          value={editTeamMember.name}
          onChange={(e) =>
            setEditTeamMember({ ...editTeamMember, name: e.target.value })
          }
          fullWidth
        />
        <TextField
          label="Email"
          value={editTeamMember.email}
          onChange={(e) =>
            setEditTeamMember({ ...editTeamMember, email: e.target.value })
          }
          fullWidth
        />
        <TextField
          label="Role"
          value={editTeamMember.role}
          onChange={(e) =>
            setEditTeamMember({ ...editTeamMember, role: e.target.value })
          }
          fullWidth
        />
        <TextField
          label="Description"
          value={editTeamMember.description}
          onChange={(e) =>
            setEditTeamMember({ ...editTeamMember, description: e.target.value })
          }
          fullWidth
          multiline
        />
        {/* Image Upload Field */}
        <input
          type="file"
          onChange={(e) =>
            setEditTeamMember({
              ...editTeamMember,
              photo: e.target.files[0]
            })
          }
          style={{ display: "block", marginBottom: "16px" }}
        />
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </form>
    )}
  </div>
</Modal>




      {/* <Modal open={showEditJobModal} onClose={handleEditCloseJob}>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "white",
          margin: "20% auto",
          width: "50%",
        }}
      >
        <h2>Edit Job</h2>

        {editJob && (   
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditJob(editJob);
          }}
        >
          <TextField
            label="Job Title"
            value={editJob.jobTitle}
            onChange={(e) =>
              setEditJob({ ...editJob, jobTitle: e.target.value })
            }
            fullWidth
            sx={{ marginBottom: "15px" }}
            required
          />
          <TextField
            label="Description"
            value={editJob.description}
            onChange={(e) =>
              setEditJob({ ...editJob, description: e.target.value })
            }
            fullWidth
            multiline
            rows={4}
            sx={{ marginBottom: "15px" }}
            required
          />
          <TextField
            label="Due Date"
            type="date"
            value={editJob.dueDate}
            onChange={(e) =>
              setEditJob({ ...editJob, dueDate: e.target.value })
            }
            fullWidth
            sx={{ marginBottom: "15px" }}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="Summary"
            value={editJob.summary}
            onChange={(e) =>
              setEditJob({ ...editJob, summary: e.target.value })
            }
            fullWidth
            sx={{ marginBottom: "15px" }}
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
          <Button onClick={handleEditCloseJob} variant="outlined">
            Cancel
          </Button>
        </form>
          )}
      </Box>
    </Modal> */}

<Modal open={showEditJobModal} onClose={handleEditCloseJob}>
      <Box
        sx={{
          padding: "20px",
          backgroundColor: "white",
          margin: "20% auto",
          width: "50%",
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Edit Job
        </Typography>

        {editJob && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEditJob(editJob);
            }}
          >
            <TextField
              label="Job Title"
              value={editJob.jobTitle}
              onChange={(e) =>
                setEditJob({ ...editJob, jobTitle: e.target.value })
              }
              fullWidth
              sx={{ marginBottom: "15px" }}
              required
            />
            <TextField
              label="Description"
              value={editJob.description}
              onChange={(e) =>
                setEditJob({ ...editJob, description: e.target.value })
              }
              fullWidth
              multiline
              rows={4}
              sx={{ marginBottom: "15px" }}
              required
            />
            <TextField
              label="Due Date"
              type="date"
              value={editJob.dueDate}
              onChange={(e) =>
                setEditJob({ ...editJob, dueDate: e.target.value })
              }
              fullWidth
              sx={{ marginBottom: "15px" }}
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              label="Summary"
              value={editJob.summary}
              onChange={(e) =>
                setEditJob({ ...editJob, summary: e.target.value })
              }
              fullWidth
              sx={{ marginBottom: "15px" }}
              required
            />
            <TextField
              label="Experience"
              value={editJob.experience}
              onChange={(e) =>
                setEditJob({ ...editJob, experience: e.target.value })
              }
              fullWidth
              sx={{ marginBottom: "15px" }}
              required
            />
            <TextField
              label="Qualifications"
              value={editJob.qualifications}
              onChange={(e) =>
                setEditJob({ ...editJob, qualifications: e.target.value })
              }
              fullWidth
              sx={{ marginBottom: "15px" }}
              required
            />
            <Typography sx={{ marginBottom: "15px" }}>
              Upload Image (if not updating, existing will be sent)
            </Typography>
            <input
              type="file"
              onChange={(e) =>
                setEditJob({ ...editJob, image: e.target.files[0] })
              }
              style={{ marginBottom: "15px", display: "block" }}
            />
             <Button type="submit" variant="contained" color="primary">
              Save Changes
            </Button>
            <Button onClick={handleEditCloseJob} variant="outlined">
              Cancel
            </Button>
          </form>
        )}
      </Box>
    </Modal>

      
    </Container>
  );
}

export default AdminPanel;

//...............multiple catgories and indsutries................

// import React, { useState, useEffect, useMemo } from 'react';
// import { TextField, Container, Box, Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import FilledButton from '../../Components/UI/Buttons/FilledButton';
// import axios from 'axios';
// import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

// const ProjectCategories = ['Embedded', 'IoT', 'PCB', 'Front End', 'Mobile'];
// const IndustryCategories = ['Health care', 'Finance', 'Defence', 'Public Sector', 'Manufacturing', 'Telecom', 'Ecommerce'];
// function AdminPanel() {
//   const [title, setTitle] = useState('');
//   const [industry, setIndustry] = useState([]);
//   const [images, setImages] = useState([]);
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState([]);
//   const [showIndustryForm, setShowIndustryForm] = useState(false);
//   const [showProjectForm, setShowProjectForm] = useState(false);
//   const [features, setFeatures] = useState('');

//   const handleFeatureChange = (event) => {
//     setFeatures(event.target.value);
//   };

//   //GET API CALL for projects.....
//   const [projectsData, setProjectsData] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   useEffect(() => {
//     axios
//       .get(process.env.REACT_APP_BACKEND_URL +'/project')
//       .then((response) => {
//         setProjectsData(response.data);
//         setCategories(response.data.map((item) => item.category));
//         setSelectedCategory(response.data[0]?.category);
//       })
//       .catch((error) => {
//         console.error('Error fetching data: ', error);
//       });
//   }, []);
//   const projectDescriptionStyle = {
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
//     display: '-webkit-box',
//     WebkitLineClamp: 2, // Maximum number of lines to display
//     WebkitBoxOrient: 'vertical',
//   };

//   const columns = [
//     {
//       accessorKey: 'category',
//       header: 'Category',
//       muiTableHeadCellProps: { sx: { color: '' } },
//     },
//     {
//       accessorKey: 'projects',
//       header: 'Projects',
//       Cell: ({ cell }) => (
//         <ul>
//           {cell.getValue().map((project) => (
//             <li key={project._id}>
//               <strong>{project.title}</strong>
//               <p style={projectDescriptionStyle}>{project.description}</p>
//             </li>
//           ))}
//         </ul>
//       ),
//     },
//   ];

//   const tableData = categories.map((category) => ({
//     category,
//     projects: projectsData.find((item) => item.category === category)?.projects || [],
//   }));
//   const table = useMaterialReactTable({
//     columns,
//     data: tableData,
//     enableColumnOrdering: true,
//     enablePagination: false,

//   });
//   //POST API CALL.....

//   const handleSubmitProjects = async (event) => {
//     event.preventDefault();

//     const formData = new FormData();
//     formData.append('title', title);
//     for (let i = 0; i < images.length; i++) {
//       formData.append('images', images[i]);
//     }
//     formData.append('description', description);
//     category.forEach((cat, index) => {
//       formData.append(`category[${index}]`, cat);
//     });

//     industry.forEach((ind, index) => {
//       formData.append(`industry[${index}]`, ind);
//     });
//     // Split the features string into an array of features
//     const featuresArray = features.split(',');
//     featuresArray.forEach((feature, index) => {
//       formData.append(`features[${index}]`, feature);
//     });

//     try {
//       await axios.post(process.env.REACT_APP_BACKEND_URL +'/project', formData);
//       setTitle('');
//       resetImages();
//       setDescription('');
//       setFeatures('');
//       setIndustry([]); // Reset to empty array
//       setCategory([]); // Reset to empty array
//       alert('Project added successfully');
//     } catch (error) {
//       console.error(error);
//       alert('Error occurred while adding project');
//     }
//   };

//   const resetImages = () => {
//     document.getElementById('fileInput').value = '';
//     setImages([]);
//   };

//   //form handeling
//   const [open, setOpen] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <Container maxWidth="md" sx={{ marginTop: "10%" }} style={{ background: '' }}>
//       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
//         <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
//           <Button onClick={() => { setShowProjectForm(true); setShowIndustryForm(false); }}>Manage Projects</Button>
//           <Button onClick={() => { }}>Manage Reviews</Button>
//         </Box>
//         {!showIndustryForm && !showProjectForm && (
//           <>
//             <Typography variant="h4" sx={{ paddingTop: '5%', paddingBottom: '5%' }}>Welcome to Octathorn Admin Panel</Typography>
//           </>
//         )}
//         {showProjectForm && (
//           <>
//             <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//               Add Projects
//             </Button>
//             <MaterialReactTable table={table} />

//             <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
//               <DialogTitle id="form-dialog-title">Manage Featured Projects</DialogTitle>
//               <DialogContent>
//                 <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
//                   <Typography variant="h4" sx={{ paddingTop: '5%', paddingBottom: '5%' }}>Manage Featured Projects</Typography>
//                   <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} required sx={{ paddingTop: '0%', paddingBottom: '5%' }} />
//                   <input type="file" multiple onChange={(e) => setImages(e.target.files)} required style={{ paddingBottom: '5%' }} id="fileInput" />

//                   <TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} required sx={{ paddingTop: '0%', paddingBottom: '5%' }} />
//                   <Autocomplete
//                     multiple
//                     sx={{ paddingTop: '0%', paddingBottom: '5%', width: 230, }}
//                     value={category}
//                     onChange={(event, newValue) => setCategory(newValue)}
//                     options={ProjectCategories}
//                     freeSolo
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Category"
//                         required
//                       />
//                     )}
//                   />
//                   <Autocomplete
//                     multiple
//                     sx={{ paddingTop: '0%', paddingBottom: '5%', width: 230, }}
//                     value={industry}
//                     onChange={(event, newValue) => setIndustry(newValue)}
//                     options={IndustryCategories}
//                     freeSolo
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Industry"
//                         required
//                       />
//                     )}
//                   />
//                   <TextField
//                     label="Features"
//                     value={features}
//                     onChange={handleFeatureChange}
//                     required
//                     multiline
//                     rows={4}
//                     sx={{ paddingTop: '0%', paddingBottom: '5%' }}
//                     helperText="Enter features separated by commas"
//                   />

//                   <FilledButton text={'Submit'} onClick={handleSubmitProjects} />
//                   <Button onClick={() => { setOpen(false); }}>Close</Button>
//                 </Box>
//               </DialogContent>
//             </Dialog>
//             <Button onClick={() => { setShowProjectForm(false); }}>Close Projects</Button>
//           </>
//         )}
//       </Box>
//     </Container>
//   );
// }

// export default AdminPanel;
